import React, { createContext, useReducer, useContext, ReactNode } from "react";

// Define the types for your state and actions
type FilterType = {
  indents: string;
  trips: string;
  payments: string;
};

interface ActionType {
  type: "INDENTS" | "TRIPS" | "PAYMENTS";
  payload: any;
}

// Create the initial state
const initialState: FilterType = {
  indents: "",
  trips: "",
  payments: "",
};

// Create a reducer function
function reducer(state: FilterType, action: ActionType): FilterType {
  const { type, payload } = action;
  switch (type) {
    case "INDENTS":
      return { ...state, indents: payload };
    case "TRIPS":
      return { ...state, trips: payload };
    case "PAYMENTS":
      return { ...state, payments: payload };
    default:
      return state;
  }
}
interface FilterContextProps {
  filters: FilterType;
  dispatchFilter: React.Dispatch<ActionType>;
}
// Create the context with a default value
const FilterContext = createContext<FilterContextProps | undefined>(undefined);

// Provider component
export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [filters, dispatchFilter] = useReducer(reducer, initialState);

  return (
    <FilterContext.Provider value={{ filters, dispatchFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

// Custom hook to use the filter context
export const useFilters = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilters must be used within a FilterProvider");
  }
  return context;
};
